import React, { useState, useEffect } from 'react';
import Cookies from 'js-cookie';

const CookieConsent: React.FC = () => {
  const [cookiesAccepted, setCookiesAccepted] = useState(false);

  useEffect(() => {
    const acceptCookies = Cookies.get('acceptCookies');
    if (acceptCookies === 'true') {
      setCookiesAccepted(true);
    }
  }, []);

  const handleCookieAcceptance = () => {
    Cookies.set('acceptCookies', 'true', { expires: 365 });
    setCookiesAccepted(true);
  };

  return cookiesAccepted ? null : (
    <div className="cookie-banner">
      <p>We use cookies for anonymous data on how many people are accessing the site, we don't capture any personal information. By continuing to use the website you accept this.</p>
      <button onClick={handleCookieAcceptance}>Accept</button>
    </div>
  );
};

export default CookieConsent;