import React from 'react';

const PrivacyPolicy = () => {
    return (
        <>
            <div className="App container mt-4">
                <p><a href="/">Back to Home</a> </p>
                <h1>Privacy Policy</h1>

                <p>Last updated: 11th August 2023</p>

                <p>Gemstone IT Services Ltd ("us", "we", or "our") operates <a href="https://bluffball.ai">https://bluffball.ai</a> (the "Site"). This page informs you of our policies regarding the collection, use, and disclosure of Personal Information we receive from users of the Site.</p>

                <p>We use anonymous information only for providing and improving the Site. By using the Site, you agree to the collection and use of information in accordance with this policy.</p>

                <h2>Log Data</h2>

                <p>Like many site operators, we collect information that your browser sends whenever you visit our Site ("Log Data"). This Log Data may include information such as your computer's Internet Protocol ("IP") address, browser type, browser version, the pages of our Site that you visit, the time and date of your visit, the time spent on those pages, and other statistics. In addition, we may use third-party services such as Google Analytics (GA4) to collect, monitor, and analyze this data.</p>

                <h2>Cookies</h2>

                <p>Cookies are files with small amounts of data, which may include an anonymous unique identifier. Cookies are sent to your browser from a web site and stored on your computer's hard drive.</p>

                <p>Like many sites, we use "cookies" to collect information. You can instruct your browser to refuse all cookies or to indicate when a cookie is being sent. However, if you do not accept cookies, you may not be able to use some portions of our Site.</p>

                <h2>Hotjar</h2>

                <p>We also use Hotjar to improve your experience on our Site. Hotjar may collect non-personally identifiable information through cookies and other technologies. This information is used to analyze user behavior and trends, evaluate the effectiveness of our Site, and gather demographic information.</p>

                <h2>Your Rights</h2>

                <p>You have the right to access, correct, or delete your Personal Information held by us. You can exercise these rights by contacting us at <a href="mailto:admin@gemstoneit.co.uk">admin@gemstoneit.co.uk</a>.</p>

                <h2>Security</h2>

                <p>The security of your Personal Information is important to us, but remember that no method of transmission over the Internet, or method of electronic storage, is 100% secure. While we strive to use commercially acceptable means to protect your Personal Information, we cannot guarantee its absolute security.</p>

                <h2>Changes To This Privacy Policy</h2>

                <p>This Privacy Policy is effective as of 11th August 2023 and will remain in effect except with respect to any changes in its provisions in the future, which will be in effect immediately after being posted on this page.</p>

                <p>We reserve the right to update or change our Privacy Policy at any time and you should check this Privacy Policy periodically. Your continued use of the Service after we post any modifications to the Privacy Policy on this page will constitute your acknowledgment of the modifications and your consent to abide and be bound by the modified Privacy Policy.</p>

                <h2>Contact Us</h2>

                <p>If you have any questions about this Privacy Policy, please contact us at:</p>

                <address>
                    Gemstone IT Services Ltd<br />
                    West Clayton Business Centre<br />
                    Berry Lane, Chorleywood<br />
                    Rickmansworth, Hertfordshire<br />
                    WD3 5EX<br />
                    United Kingdom<br />
                    Phone: 03330124097<br />
                    Email: <a href="mailto:admin@gemstoneit.co.uk">admin@gemstoneit.co.uk</a><br />
                    VAT: GB386870444<br />
                    Company registration: 13504008<br />
                    DUNS number: 227871275<br />
                    ICO Registration: ZB250270
                </address>
            </div>
        </>
    );
};

export default PrivacyPolicy;
