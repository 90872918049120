import React, { useState, useEffect, ChangeEvent, useRef } from 'react';
import axios from 'axios';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import moss from './assets/images/moss.png';
import harry from './assets/images/harry.png';
import { SocialIcon } from 'react-social-icons';
import { FacebookShareButton } from 'react-share';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import PrivacyPolicy from './components/PrivacyPolicy';
import CookieConsent from './components/CookieConsent';
import 'bootstrap-icons/font/bootstrap-icons.css';
import Accordion from 'react-bootstrap/Accordion';

interface Team {
  team: {
    id: number;
    name: string;
  };
  venue: Venue;
}

interface CategorizedFixtures {
  past: Fixture[];
  yesterday: Fixture[];
  today: Fixture[];
  tomorrow: Fixture[];
  future: Fixture[];
}

interface Venue {
  id: number;
  name: string;
  address: string;
  city: string;
  capacity: number;
  surface: string;
  image: string;
};

interface Fixture {
  fixture: {
    id: number;
    referee: string;
    timezone: string;
    date: string;
    timestamp: number;
    periods: {
      first: number;
      second: number;
    };
    venue: {
      id: number;
      name: string;
      city: string;
    };
    status: {
      long: string;
      short: string;
      elapsed: number;
    };
  };
  league: {
    id: number;
    name: string;
    country: string;
    logo: string;
    flag: string;
    season: number;
    round: string;
  };
  teams: {
    home: {
      id: number;
      name: string;
      logo: string;
      winner: boolean;
    };
    away: {
      id: number;
      name: string;
      logo: string;
      winner: boolean;
    };
  };
  goals: {
    home: number;
    away: number;
  };
  score: {
    halftime: {
      home: number;
      away: number;
    };
    fulltime: {
      home: number;
      away: number;
    };
    extratime: {
      home: number | null;
      away: number | null;
    };
    penalty: {
      home: number | null;
      away: number | null;
    };
  };
}

interface Comment {
  comments: string;
  typing: boolean;
}

const App = () => {
  const [teams, setTeams] = useState<Team[]>([]);
  const [selectedTeam, setSelectedTeam] = useState<Team | null>(null);
  const [fixtures, setFixtures] = useState<Fixture[]>([]);
  const [selectedLeague, setSelectedLeague] = useState<number | null>(null);
  const [selectedFixture, setSelectedFixture] = useState<Fixture | null>(null);
  const [comments, setComments] = useState<Comment>({ comments: '', typing: false });
  const [selectedSeason, setSelectedSeason] = useState<string>('');
  const [selectedType, setSelectedType] = useState<string | null>(null);
  const [loadingMessage, setLoadingMessage] = useState('Loading witty and insightful comments');
  const [activeKey, setActiveKey] = useState<string | null>(null);
  // eslint-disable-next-line
  const [categorizedFixtures, setCategorizedFixtures] = useState<CategorizedFixtures>({
    past: [],
    yesterday: [],
    today: [],
    tomorrow: [],
    future: []
  });

  const [flashDropdown, setFlashDropdown] = useState(false);

  const commentsRef = useRef<HTMLDivElement>(null);
  const styleDropdownRef = useRef<HTMLDivElement>(null);

  const selectFixture = async (fixture: Fixture) => {
    setSelectedLeague(4);  // Assuming league is always 'Euro 2024' for this fixture
    setSelectedSeason('2024');  // Assuming the season is always '2024'
    try {
      // Fetch teams
      const teamsResponse = await axios.get<Team[]>(`${process.env.REACT_APP_API_URL}/teams`, {
        params: {
          leagueId: 4,  // Assuming league ID for Euro 2024 is 4
          season: '2024'
        }
      });
      setTeams(teamsResponse.data);

      // Automatically select a team, here selecting the home team for simplicity
      const selectedTeam = teamsResponse.data.find(team => team.team.id === fixture.teams.home.id) || null;
      setSelectedTeam(selectedTeam);

      // Proceed only if a team is found
      if (selectedTeam) {
        // Fetch fixtures for the selected team
        const fixturesResponse = await axios.get<Fixture[]>(`${process.env.REACT_APP_API_URL}/fixtures/${selectedTeam.team.id}`, {
          params: {
            season: '2024',
            leagueId: 4
          }
        });
        setFixtures(fixturesResponse.data);

        // Select the specific fixture, assuming you want to select the one that was clicked
        setSelectedFixture(fixture);

        // Scroll to the style dropdown
        if (styleDropdownRef.current) {
          styleDropdownRef.current.scrollIntoView({ behavior: 'smooth' });
          flashStyleDropdown();
        }

      }
    } catch (error) {
      console.error('Error setting up the fixture:', error);
    }
  };

  const flashStyleDropdown = () => {
    setFlashDropdown(true);
    setTimeout(() => setFlashDropdown(false), 5000); // Turn off flash after 3 seconds
  };

  const categorizeFixtures = (fixtures: Fixture[]) => {
    const categorized: CategorizedFixtures = { past: [], yesterday: [], today: [], tomorrow: [], future: [] };
    const today = new Date();
    today.setHours(0, 0, 0, 0); // Normalize today's date

    fixtures.forEach(fixture => {
      const fixtureDate = new Date(fixture.fixture.date);
      fixtureDate.setHours(0, 0, 0, 0); // Normalize fixture date

      const diff = (fixtureDate.getTime() - today.getTime()) / (1000 * 3600 * 24);

      if (diff < -1) {
        categorized.past.push(fixture);
      } else if (diff === -1) {
        categorized.yesterday.push(fixture);
      } else if (diff === 0) {
        categorized.today.push(fixture);
      } else if (diff === 1) {
        categorized.tomorrow.push(fixture);
      } else if (diff > 1) {
        categorized.future.push(fixture);
      }
    });

    setCategorizedFixtures(categorized);
  };

  useEffect(() => {
    if (comments.typing) {
      const timer1 = setTimeout(() => {
        setLoadingMessage('Still loading...');
      }, 4000);

      const timer2 = setTimeout(() => {
        setLoadingMessage('Getting there...');
      }, 8000);

      const timer3 = setTimeout(() => {
        setLoadingMessage('Just fine tuning...');
      }, 12000);

      const timer4 = setTimeout(() => {
        setLoadingMessage('It\'s not broken we promise...');
      }, 18000);

      return () => {
        clearTimeout(timer1);
        clearTimeout(timer2);
        clearTimeout(timer3);
        clearTimeout(timer4);
      };
    } else {
      setLoadingMessage('Loading witty and insightful comments');
    }
  }, [comments.typing]);

  useEffect(() => {
    let intervalId: NodeJS.Timeout | null = null;

    const fetchData = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/leagues/4/fixtures?season=2024`);

        //dummy data for testing
        // if (response.data.length > 0) {
        //   const randomElapsed = Math.floor(Math.random() * 90) + 1; // Generate a random number between 1 and 90
        //   response.data[0].fixture.status = {
        //     long: 'In Play',
        //     short: '1H',
        //     elapsed: randomElapsed
        //   }; // Fake an in-progress game with random elapsed time
        // }
        categorizeFixtures(response.data);

        // Check if any games are in progress
        const inProgress = response.data.some((fixture: { fixture: { status: { short: string; }; }; }) =>
          ['1H', '2H', 'ET', 'HT', 'BT', 'P', 'LIVE'].includes(fixture.fixture.status.short)
        );

        if (inProgress) {
          // Set up an interval if not already set
          if (!intervalId) {
            intervalId = setInterval(fetchData, 10000); // Refresh every 10 seconds
          }
        } else {
          // Clear the interval if no games are in progress
          if (intervalId) {
            clearInterval(intervalId);
            intervalId = null;
          }
        }
      } catch (error) {
        console.error('Error fetching data:', error);
        // Make sure to clear the interval on error to prevent infinite loops
        if (intervalId) {
          clearInterval(intervalId);
          intervalId = null;
        }
      }
    };

    // Initial fetch
    fetchData();

    // Cleanup function to clear the interval when the component unmounts
    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, []);

  useEffect(() => {
    if (selectedLeague && selectedSeason) {
      axios.get<Team[]>(`${process.env.REACT_APP_API_URL}/teams`, {
        params: {
          leagueId: selectedLeague,
          season: selectedSeason
        }
      })
        .then(response => {
          setTeams(response.data);
        });
    }
  }, [selectedLeague, selectedSeason]);

  useEffect(() => {
    if (selectedFixture && selectedType) {
      setComments({ comments: '', typing: true });
      axios.get<Comment>(`${process.env.REACT_APP_API_URL}/comments/${selectedFixture.fixture.id}?type=${selectedType}`)
        .then(response => {
          setComments({ comments: response.data.comments, typing: false });
        });
    }
  }, [selectedFixture, selectedType]);

  const handleTeamChange = (event: ChangeEvent<HTMLSelectElement>) => {
    const team = teams.find(team => team.team.id === parseInt(event.target.value));
    setSelectedTeam(team || null);
    setSelectedFixture(null); // Clear the selectedFixture

    if (team && selectedSeason) {
      fetchFixtures(team.team.id, selectedSeason, selectedLeague || 0);
    }
  };

  const handleTypeChange = (event: ChangeEvent<HTMLSelectElement>) => {
    setSelectedType(event.target.value);
  };

  const fetchFixtures = (teamId: number, season: string, leagueId: number) => {
    axios.get<Fixture[]>(`${process.env.REACT_APP_API_URL}/fixtures/${teamId}`, {
      params: {
        season: season,
        leagueId: leagueId
      }
    })
      .then(response => {
        const sortedFixtures = response.data.sort((a, b) => (new Date(a.fixture.date).getTime() - new Date(b.fixture.date).getTime()) as number);
        setFixtures(sortedFixtures);
      })
      .catch(error => console.error('Error fetching fixtures:', error));
  };

  const handleLeagueChange = (event: ChangeEvent<HTMLSelectElement>) => {
    const leagueId = parseInt(event.target.value);
    setSelectedLeague(leagueId);

    // Automatically select the season for the Euro Championship
    if (leagueId === 4) { // Assuming '4' is the ID for "Euro Championship"
      setSelectedSeason('2024');
      updateTeamsAndFixtures(leagueId, '2024');
    }
  };

  const handleSeasonChange = (event: ChangeEvent<HTMLSelectElement>) => {
    const season = event.target.value.split('/')[0];
    setSelectedSeason(season);

    if (selectedLeague) {
      updateTeamsAndFixtures(selectedLeague, season);
    }
  };

  const handleFixtureChange = (event: ChangeEvent<HTMLSelectElement>) => {
    const fixture = fixtures.find(fixture => fixture.fixture.id === parseInt(event.target.value));
    setSelectedFixture(fixture || null);

    // Scroll to the comments section
    if (commentsRef.current) {
      commentsRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const regenerateComments = () => {
    if (selectedFixture && selectedType) {
      setComments({ comments: '', typing: true });
      axios.get<Comment>(`${process.env.REACT_APP_API_URL}/comments/${selectedFixture.fixture.id}?type=${selectedType}`)
        .then(response => {
          setComments({ comments: response.data.comments, typing: false });
        });
    }
  };


  const updateTeamsAndFixtures = (leagueId: number, season: string) => {
    // Fetch teams based on the league and season
    axios.get<Team[]>(`${process.env.REACT_APP_API_URL}/teams`, {
      params: {
        leagueId: leagueId,
        season: season
      }
    })
      .then(response => {
        setTeams(response.data);
        if (response.data.length > 0) {
          // Optionally auto-select the first team and fetch its fixtures
          setSelectedTeam(response.data[0]);
          fetchFixtures(response.data[0].team.id, season, leagueId);
        }
      })
      .catch(error => console.error('Error fetching teams:', error));
  };

  useEffect(() => {
    if (selectedLeague === 4) {
      setSelectedSeason('2024');
    }
  }, [selectedLeague]);

  type FixtureCategory = 'past' | 'yesterday' | 'today' | 'tomorrow' | 'future';

  // eslint-disable-next-line
  const FixtureAccordion = ({ fixtures }: { fixtures: CategorizedFixtures }): JSX.Element => {

    const handleSelect = (key: string) => {
      // Toggle functionality: click to open, click again to close
      setActiveKey(activeKey === key ? null : key);
    };

    return (
      <>
        <h2 className="section-title-euro">⭐ Euro 2024 - Quick Access</h2>
        {/* @ts-ignore */}
        <Accordion defaultActiveKey={activeKey || 'today'} onSelect={handleSelect}>
          {(Object.keys(fixtures) as FixtureCategory[])
            .filter(key => !['past', 'future'].includes(key) && fixtures[key].length > 0)  // Exclude "past" and "future", and any empty categories
            .map(key => (
              // @ts-ignore
              <Accordion.Item eventKey={key} key={key}>
                {/* @ts-ignore */}
                <Accordion.Header>{`${key.charAt(0).toUpperCase() + key.slice(1)}'s Matches`}</Accordion.Header>
                {/* @ts-ignore */}
                <Accordion.Body>
                  <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'space-around' }}>
                    {fixtures[key].map((fixture) => (
                      <div key={fixture.fixture.id} className="fixture-block" onClick={() => selectFixture(fixture)} style={{ margin: '10px', borderRadius: '8px', border: '1px solid #ccc', padding: '25px 10px 10px', width: '300px', position: 'relative', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', background: '#fff' }}>
                        <div style={{ position: 'absolute', top: '10px', left: '10px', fontSize: 'small' }}>
                          Round: {fixture.league.round}
                        </div>
                        <div style={{ position: 'absolute', top: '10px', right: '10px', fontSize: 'small' }} className={(fixture.fixture.status.short === "1H" || fixture.fixture.status.short === "2H" || fixture.fixture.status.short === "ET" || fixture.fixture.status.short === "LIVE") ? "green-text" : ""}>
                          {fixture.fixture.status.elapsed ? `${fixture.fixture.status.elapsed} mins` : 'Not started'}
                          {["1H", "2H", "ET", "LIVE"].includes(fixture.fixture.status.short) && <span className='flashing'>'</span>}
                        </div>
                        <div style={{ fontSize: 'medium', textAlign: 'center', padding: '20px 0' }}>
                          {fixture.teams.home.name} vs {fixture.teams.away.name}
                        </div>
                        {fixture.goals.home !== null && fixture.goals.away !== null && (
                          <div style={{ fontSize: 'medium', fontWeight: 'bold' }}>
                            {fixture.goals.home} - {fixture.goals.away}
                          </div>
                        )}
                        <div style={{ position: 'absolute', bottom: '10px', right: '10px', fontSize: 'small', color: '#007bff', opacity: 0.5 }} className="analyse-note">
                          <i className="fas fa-burst"></i> ⭐ Analyse
                        </div>
                      </div>
                    ))}
                  </div>
                </Accordion.Body>
              </Accordion.Item>
            ))}
        </Accordion>
      </>
    );
  };

  return (
    <Router>
      <title>Bluffball - Fool your friends with AI generated football chat!</title>

      <Routes>
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="*" element={
          <div className="App container mt-4">
            <CookieConsent />
            <h1 className="text-center mt-5">Bluffball!</h1>
            <h4 className="text-center mb-4">Do you know nothing about football but want to blag your way through that conversation at the pub? Would you like to learn how to talk like a real football fan? Do friends often tell you that you sound a bit like an AI bot? Bluffball is for you!</h4>
            <p className="text-center mb-4 text-muted">*may sometimes produce very outdated stuff or total nonsense, especially for matches in the future</p>
            <div className="row mb-4">
              <div className="col-md-6 text-center">
                <div className="image-cropped">
                  <img src={moss} alt="Moss" className="img-fluid" />
                </div>
                <p className="caption">Did you see that ludicrous display last night?</p>
              </div>
              <div className="col-md-6 text-center">
                <div className="image-cropped">
                  <img src={harry} alt="Harry" className="img-fluid" />
                </div>
                <p className="caption">The thing about Arsenal is they always try and walk it in...</p>
              </div>
            </div>

            <h2 className="section-title">Choose Match</h2>

            <div className="row mb-3">
              <div className="col-md-6">
                <select className="form-select large-dropdown" onChange={handleLeagueChange} value={selectedLeague ?? ''} >
                  <option>Select a competition</option>
                  <option value="2">Champions League</option>
                  <option value="39">Premier League</option>
                  <option value="4">Euro 2024</option>
                </select>
              </div>
              <div className="col-md-6">
                <select className="form-select large-dropdown" onChange={handleSeasonChange} disabled={selectedLeague === 4}>
                  <option>Select a season</option>
                  <option selected={selectedSeason === '2022' ? true : undefined} value="2022/2023">2022/2023</option>
                  <option selected={selectedSeason === '2023' ? true : undefined} value="2023/2024">2023/2024</option>
                  <option selected={selectedSeason === '2024' ? true : undefined} value="2024/2025">2024/2025</option>
                </select>
              </div>
            </div>
            <div className="row mb-3">
              <div className="col-md-12">
                <select className="form-select large-dropdown" onChange={handleTeamChange} value={selectedTeam?.team.id ?? ''}>
                  <option>Select a team</option>
                  {teams.map(team => (
                    <option key={team.team.id} value={team.team.id}>{team.team.name}</option>
                  ))}
                </select>
              </div>
            </div>

            <div ref={styleDropdownRef} className="row mb-3">
              <div className="col-md-12">
                <select className={`form-select large-dropdown ${flashDropdown ? "flash-select" : ""}`} onChange={handleTypeChange} value={selectedType || ''}>
                  <option>Select output style</option>
                  <option value="banter">Debate between two fans down the pub</option>
                  <option value="monologue">Series of "witty" remarks</option>
                  <option value="report">Serious match report</option>
                </select>
              </div>
            </div>

            {selectedType && selectedTeam && (
              <div className="row mb-3">
                <div className="col-md-12">
                  <select className="form-select large-dropdown" onChange={handleFixtureChange} value={selectedFixture?.fixture.id ?? ''}>
                    <option>Select a fixture</option>
                    {fixtures.map(fixture => (
                      <option key={fixture.fixture.id} value={fixture.fixture.id}>
                        {fixture.teams.home.name} vs {fixture.teams.away.name} - {new Date(fixture.fixture.date).toLocaleDateString()}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            )}

            {selectedFixture && (
              <div className="row mb-3">
                <div className="col-md-12">
                  <h5 className="mb-2">
                    Full Time Score: {selectedFixture.score.fulltime.home} - {selectedFixture.score.fulltime.away}
                  </h5>
                </div>
              </div>
            )}

            {selectedFixture && comments.typing && (
              <div className="text-center loading-container">
                <div>{loadingMessage}</div>
                <div className="spinner-border text-primary" role="status">
                  <span className="visually-hidden">Loading...</span>
                </div>
              </div>
            )}

            {selectedType && selectedFixture && !comments.typing && typeof comments.comments !== 'undefined' && (
              <>
                <div className="mb-2">
                  <button className="btn btn-primary" onClick={regenerateComments}>
                    <i className="bi bi-arrow-repeat"></i> Regenerate
                  </button>
                </div>
                <div ref={commentsRef} className="comments">
                  {comments.comments.split('\n').map((line, index) => (
                    <div key={index}>{line}</div>
                  ))}
                </div>
              </>
            )}
            <div>
              <p>Like this? Tell your friends!</p>
              <FacebookShareButton url="https://bluffball.ai">
                <SocialIcon network="facebook" />
              </FacebookShareButton>
              <SocialIcon url="http://twitter.com/share?url=https://bluffball.ai&text=Check out bluffball.ai!" />
              <SocialIcon url="http://linkedin.com/shareArticle?url=https://bluffball.ai&title=Check out bluffball.ai!" />
              <SocialIcon url="https://api.whatsapp.com/send?text=Check out https://bluffball.ai!" />
            </div>
            <footer className="text-center mt-4">
              <p>
                {/* eslint-disable-next-line react/jsx-no-target-blank */}
                &copy; 2024 <a href="https://gemstoneit.co.uk" target="_blank" rel="noopener">Gemstone IT Services Ltd</a><br />
              </p>
              <p>
                {/* eslint-disable-next-line react/jsx-no-target-blank */}
                <a href="https://gemstoneit.co.uk" target="_blank" rel="noopener">In addition to this nonsense, we also build serious websites, mobile apps, cloud services and analytics solutions</a><br />
                Inspired by the IT Crowd episode "Are we not men?"
              </p>
              <p><Link to={{ pathname: "/privacy-policy" }}>Privacy Policy</Link></p>
            </footer>
          </div>
        } />
      </Routes>
    </Router>
  );
};

export default App;